.topmenu {
  position: fixed; 
  top: 0;
  right:  0;
  bottom:   800px;
  left:   0;
  z-index:  1;
}

.NavH { 
	width: auto; 
	height: 80px;
	display: flex;
		
}

.bk {
	background: linear-gradient(to left, rgba(0,102,0,0.7), rgba(0,153,0,0.7) 100%);
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
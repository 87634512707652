body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modImg { 
	width: auto; 
	height: 200px;
	display: -webkit-flex;
	display: flex;
		
} 

.modC { 
	width: auto; 
	height: 550px;
	display: -webkit-flex;
	display: flex; 
		
} 
.modM { 
	width: auto; 
	height: 850px;
	display: -webkit-flex;
	display: flex; 
		
} 


.Footer { 
	width: auto; 
	height: 50px;
	
		
} 

.card2 {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 0px;
    word-wrap: break-word;
    border: .0625rem solid rgba(0, 0, 0, .05);
    border-radius: .25rem;
    background-color: #fff;
    background-clip: border-box;
}

.card2 .card-blockquote {
    position: relative;
    padding: 2rem;
}


.blockquote {
    margin: 0 0 1rem;
}


.topmenu {
  position: fixed; 
  top: 0;
  right:  0;
  bottom:   800px;
  left:   0;
  z-index:  1;
}

.NavH { 
	width: auto; 
	height: 80px;
	display: -webkit-flex;
	display: flex;
		
}

.bk {
	background: linear-gradient(to left, rgba(0,102,0,0.7), rgba(0,153,0,0.7) 100%);
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.App {
  text-align: center;
}

.center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.particles {
  position: fixed; 
  top: 0;
  right:  0;
  bottom:   0;
  left:   0;
  z-index:  -1;
}


.app {
  max-width: 600px;
  width: 100%;
  margin: 20px auto;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #052b52;
}

.current-song {
  width: 100%;
  text-align: center;
  color: #052b52;
  font-size: 14px;
}


.modImg { 
	width: auto; 
	height: 200px;
	display: flex;
		
} 

.modC { 
	width: auto; 
	height: 550px;
	display: flex; 
		
} 
.modM { 
	width: auto; 
	height: 850px;
	display: flex; 
		
} 


.Footer { 
	width: auto; 
	height: 50px;
	
		
} 

.card2 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0px;
    word-wrap: break-word;
    border: .0625rem solid rgba(0, 0, 0, .05);
    border-radius: .25rem;
    background-color: #fff;
    background-clip: border-box;
}

.card2 .card-blockquote {
    position: relative;
    padding: 2rem;
}


.blockquote {
    margin: 0 0 1rem;
}


.App {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}

.particles {
  position: fixed; 
  top: 0;
  right:  0;
  bottom:   0;
  left:   0;
  z-index:  -1;
}


.app {
  max-width: 600px;
  width: 100%;
  margin: 20px auto;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #052b52;
}

.current-song {
  width: 100%;
  text-align: center;
  color: #052b52;
  font-size: 14px;
}
